import React from 'react'
import styled from 'styled-components'
import { HeroImageTitle } from 'components/new/hero'
import H2 from 'components/new/typography/h2'
import P from 'components/new/typography/p'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import Content from 'components/new/content'
import { graphql, Link } from 'gatsby'
import Spacer from 'components/new/spacer'
import Button from 'components/button/button'

const PartsDropBoxPage = props => {
  const {
    data: { heroImage },
  } = props
  return (
    <Layout>
      <Helmet>
        <title>Parts Drop Box | Hutson Inc</title>
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Parts',
                'item': 'https://www.hutsoninc.com/parts/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Parts Drop Box',
                'item': 'https://www.hutsoninc.com/parts/parts-drop-box/',
              },
            ],
          })}
        </script>
      </Helmet>

      <Hero
        image={heroImage.childImageSharp.gatsbyImageData}
        title='Parts Drop Box'
        overlayOpacity={0.3}
      />

      <Content kind='full'>
        <H2>Convenient parts pick-up</H2>
        <P>
          Hutson has Parts Drop Boxes scattered across our area to help you get the parts you need
          quicker. When you do not have time to make it to the store to pick up a part, we can drop
          them at a Drop Box near you! Simply contact a store near you and, if we have the part in
          stock, we will have it to your nearest Drop Box by the next day. If we have to order the
          part, we can have it there within two days.{' '}
          <Link to='/locations/'>Contact your local Hutson store</Link> to have parts dropped off or
          for more information on where your closest Parts Drop Box is located.
        </P>
        <Spacer size='m' />
        <Button as={ButtonLink} to='/locations/' color='green' ghost>
          Contact your nearest Hutson store
        </Button>
        <Spacer />
        <H2>Find a parts drop box near you</H2>
        <Spacer size='m' />
        <MapContainer>
          <iframe
            src='https://www.google.com/maps/d/embed?mid=1Tw_icAn2k4UbjUlyZm66PMCetCvOm1U&ehbc=2E312F'
            title='Hutson Parts Drop Box Locations'
          ></iframe>
        </MapContainer>
      </Content>
    </Layout>
  )
}

const Hero = styled(HeroImageTitle)`
  height: 300px;

  @media (min-width: 900px) {
    height: 450px;
  }
`

const ButtonLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
`

const MapContainer = styled.div`
  height: 75vh;
  position: relative;
  width: 100%;

  iframe {
    height: 100% !important;
    padding-bottom: 0 !important;
    position: absolute !important;
    width: 100% !important;
  }

  @media (min-width: 900px) {
    height: 0;
    padding-bottom: 56.25%;
  }

  @media (max-width: 899px) {
    min-height: 500px;
  }
`

export const pageQuery = graphql`
  {
    heroImage: file(relativePath: { eq: "parts/parts-drop-box-hero.jpg" }) {
      ...FullWidthImage
    }
  }
`

export default PartsDropBoxPage

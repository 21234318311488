import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import { size as sizes } from 'styles/theme/size'

const sizesArr = Object.keys(sizes)

const Spacer = ({ className, size, type, variable, ...props }) => {
  return (
    <StyledSpacer
      className={`spacer ${className}`}
      size={size}
      type={type}
      variable={variable}
      {...props}
    />
  )
}

Spacer.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(sizesArr).isRequired,
  type: PropTypes.oneOf(['margin', 'padding']).isRequired,
  variable: PropTypes.bool.isRequired,
}

Spacer.defaultProps = {
  className: '',
  size: 'xl',
  type: 'margin',
  variable: false,
}

const getNextSmallestSize = size => {
  const currentSizeIndex = sizesArr.findIndex(str => str === size)
  if (currentSizeIndex === 0) {
    return size
  }
  return sizesArr[currentSizeIndex - 1]
}

const StyledSpacer = styled.div`
  margin-top: ${props =>
    props.type === 'margin'
      ? props.theme.size[props.variable ? getNextSmallestSize(props.size) : props.size]
      : 0};
  padding-top: ${props =>
    props.type === 'padding'
      ? props.theme.size[props.variable ? getNextSmallestSize(props.size) : props.size]
      : 0};

  @media (min-width: 900px) {
    margin-top: ${props => (props.type === 'margin' ? props.theme.size[props.size] : 0)};
    padding-top: ${props => (props.type === 'padding' ? props.theme.size[props.size] : 0)};
  }
`

export default Spacer
